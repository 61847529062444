import { Injectable } from '@angular/core';
import { FilterStorageService, Page } from './filterStorage.service';

@Injectable({
	providedIn: 'root'
})
export class CustomerPortalSearchService {
	private search: any = null;
	private currentPage: Page | null = null;

	constructor(private filterStorage: FilterStorageService) { }

	/**
	 * Lädt den gespeicherten filter.
	 * @param page Seite dessen filter geladen werden soll.
	 */
	public getSearch<Type>(page: Page): Type {
		this.search = this.filterStorage.loadFilter<Type>(page);
		this.currentPage = page;
		return this.search;
	}

	/**
	 * Speichert den letzten filter.
	 */
	public saveFilter(): void {
		if (this.search != null && this.currentPage) {
			this.filterStorage.saveFilter(this.search, this.currentPage);
		}
	}
}
